import { EnvironmentConfig, EnvironmentType } from './environment-config';

const currentDomain = window.location.hostname.includes('qa.ineedservice.co') ? 'qa.ineedservice.co' : 'propup-qa.com';

export const environment: EnvironmentConfig = {
  type: EnvironmentType.QA,
  ver: '1.5.1',
  authBaseUrl: 'https://propupqab2c.b2clogin.com/propupqab2c.onmicrosoft.com/oauth2/v2.0',
  apiUrl: 'https://api.propup-qa.com/api',
  openApiUrl: 'https://api.propup-qa.com/openApi',
  notificationsUrl: 'https://api.propup-qa.com/notifications',
  notificationsAllUrl: 'https://api.propup-qa.com/all_notifications',
  disableForm: true,
  showAuthorizeLogs: false,
  showBuildTime: true,
  showEasterEggs: true,
  azureAuthConfig: {
    b2cDirectory: 'propupqab2c',
    stsBase: 'propupqab2c.b2clogin.com',
    tenant: 'propupqab2c.onmicrosoft.com',
    policyKey: 'B2C_1_SignUpSignIn',
    stsServer: 'https://propupqab2c.b2clogin.com/propupqab2c.onmicrosoft.com/B2C_1_SignUpSignIn/v2.0',
    clientID: '0aa4f301-92be-4e7f-95e5-81dcd88e3068',
    b2cScopes: 'openid offline_access 0aa4f301-92be-4e7f-95e5-81dcd88e3068',
    redirectUrl: `https://${currentDomain}/accept-token`,
    stsServerSignInEmail: 'https://propupqab2c.b2clogin.com/propupqab2c.onmicrosoft.com/B2C_1A_signin_with_email/v2.0',
    policyKeySignInEmail: 'B2C_1A_signin_with_email',
    postLogoutRedirectUrl: `https://${currentDomain}/login`,
    forgotPasswordUrl: '',
  },
};
